
import Vue from 'vue';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'CalendarSlotNew',

	props: {
		unavailable: {
			type: Boolean,
			required: true
		},
		selected: {
			type: Boolean,
			default: false
		},
		selectedPromo: {
			type: Boolean,
			default: false
		},
		selectedPeaktime: {
			type: Boolean,
			default: false
		},
		selectedOfftime: {
			type: Boolean,
			default: false
		},
		index: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			awsPath: globalAws,
		};
	},

	methods: {
		slotClicked() {
			this.$emit('slot-clicked', this.index);
		}
	}
});

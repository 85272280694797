
import Vue from 'vue';
import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import globalAws from '@/mixins/globalAws';
import currentDomain from '@/mixins/currentDomain';
import cookieConsent from '@/mixins/cookieConsent';

export default Vue.extend({
	name: 'HowToVideo',

	components: { SectionHeading },

	mixins: [currentDomain, cookieConsent],

	props: {
		video: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			awsPath: globalAws,
			isHydrated: false,
			showYtOverlay: true,
			showYtOverlayNote: false,
		}
	},

	mounted() {
		this.isHydrated = true
	},

	methods: {
		handleIframeClick() {
			this.showYtOverlay = false;
			this.showYtOverlayNote = true;
		},

		playVideo() {
			this.showYtOverlay = false;
			this.showYtOverlayNote = false;
		},

		doNotPlayVideo() {
			this.showYtOverlayNote = false;
			this.showYtOverlay = true;
		}
	}
});

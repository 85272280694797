
import Vue from 'vue';
import type { PropType } from 'vue';

import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';
import MISC_DATA from '@/store/modules/MiscDataModule';

import { Truck, ITruckGroupBySize } from '@/types/truck';

import MapTruckCarouselNew from '@/components/map/MapTruckCarouselNew.vue';

export default Vue.extend({
	name: 'MapChooseSizeNew',

	components: { MapTruckCarouselNew },

	props: {
		trucks: {
			type: Object as PropType<ITruckGroupBySize>,
			default: null
		},
		siteId: {
			type: String,
			default: ''
		},
		disableOverlay: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			picked_pool: [] as Truck[],
			componentUpdate: 0,
		};
	},

	computed: {
		UTILS: () => UTILS,
		TRUCKS: () => TRUCKS,
		MISC_DATA: () => MISC_DATA,

		sizeCards(): any {
			const size_cards = [
				{
					label: 'L',
					color: 'cyan123',
					truck_amount: this.trucks.l ? this.trucks.l.length : 0,
					truck_price: this.MISC_DATA.truck_min_price_per_location?.L?.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price_per_location?.L?.price_6
				},
				{
					label: 'XL',
					color: 'orange123',
					truck_amount: this.trucks.xl ? this.trucks.xl.length : 0,
					truck_price: this.MISC_DATA.truck_min_price_per_location?.XL?.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price_per_location?.XL?.price_6,
				},
				{
					label: 'XXL',
					color: 'pink123',
					truck_amount: this.trucks.xxl ? this.trucks.xxl.length : 0,
					truck_price: this.MISC_DATA.truck_min_price_per_location?.XXL?.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price_per_location?.XXL?.price_6
				}
			];
			return size_cards.filter((c: any) => c.truck_amount > 0);
		},

		getDefaultPickedTruck(): any {
			return this.trucks[this.sizeCards[0].label.toLowerCase()];
		},
	},

	methods: {
		updateTruckSize(val: string) {
			this.componentUpdate += 1;
			this.picked_pool = this.trucks[val.toLocaleLowerCase()];
		}
	},

	watch: {
		siteId: {
			async handler(newVal: string, oldVal: string,) {
				if (newVal !== oldVal) {
					await this.MISC_DATA.GET_TRUCK_MIN_PRICES_PER_LOCATION({ domain: this.$getDomain(), site: newVal });
				}
			},
			immediate: true,
		},
	}
});

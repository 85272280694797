
import Vue from 'vue';

import MISC_DATA from '@/store/modules/MiscDataModule';

import currentDomain from '@/mixins/currentDomain';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'Advantages',

	mixins: [currentDomain],

	data() {
		return {
			awsPath: globalAws,
		};
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		truckMockup(): string {
			return this.MISC_DATA.data_per_bu.images.truck_side_mockups.l;
		},

		bps() {
			let price = this.MISC_DATA.prices.starting_price;

			if (
				this.MISC_DATA.truck_min_price.L.price_6 != 0 &&
				this.MISC_DATA.truck_min_price.L.price_6 < price
			) {
				price = this.MISC_DATA.truck_min_price.L.price_6;
			}

			if (
				this.MISC_DATA.truck_min_price.XL.price_6 != 0 &&
				this.MISC_DATA.truck_min_price.XL.price_6 < price
			) {
				price = this.MISC_DATA.truck_min_price.XL.price_6;
			}

			if (
				this.MISC_DATA.truck_min_price.XXL.price_6 != 0 &&
				this.MISC_DATA.truck_min_price.XXL.price_6 < price
			) {
				price = this.MISC_DATA.truck_min_price.XXL.price_6;
			}

			return [
				this.$t('home.advantages.cheap', { price: this.$n(price, 'currencyShort') }),
				this.$t('home.advantages.digital'),
				this.$t('home.advantages.license'),
				this.$t('home.advantages.always_available'),
				this.$t('home.advantages.free_km'),
			];
		},

		items() {
			let price = this.MISC_DATA.truck_min_price.L.price_24;

			if (
				this.MISC_DATA.truck_min_price.XL.price_24 != 0 &&
				this.MISC_DATA.truck_min_price.XL.price_24 < price
			) {
				price = this.MISC_DATA.truck_min_price.XL.price_24;
			}

			if (
				this.MISC_DATA.truck_min_price.XXL.price_24 != 0 &&
				this.MISC_DATA.truck_min_price.XXL.price_24 < price
			) {
				price = this.MISC_DATA.truck_min_price.XXL.price_24;
			}

			return [
				{
					icon: 'mdi-clock-outline',
					title: this.$t('home.explained.digital_title'),
					text: this.$t('home.explained.digital_text'),
					color: 'cyan123',
				},
				{
					icon: 'mdi-cash',
					title: this.$t('home.explained.price_title'),
					text: this.$t('home.explained.price_text', { price: this.$n(price, 'currencyShort') }),
					color: 'orange123',
				},
				{
					icon: 'mdi-van-utility',
					title: this.$t('home.explained.deposit_title'),
					text: this.$t('home.explained.deposit_text'),
					color: 'pink123',
				},
			];
		},
	},
});

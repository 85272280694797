
import Vue from 'vue';
import USER from '@/store/modules/UserModule';

export default Vue.extend({

	name: 'InvoiceBanner',

	data() {
		return {
			show_sheet: false
		};
	},

	computed: {
		USER: () => USER,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		userHasOverdueInvoice(): boolean {
			return this.USER.data.overdue_invoices;
		}
	},

	created() {
		this.show_sheet = this.userHasOverdueInvoice
	}

});


import Vue from 'vue';
import type { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n/types';
import { IPartner } from '@/types/misc_data';

export default Vue.extend({
	name: 'OfficialPartnersText',

	props: {
		partner: {
			type: Object as PropType<IPartner>,
			required: true
		},

		mainText: Boolean
	},

	computed: {
		translationPath(): TranslateResult {
			return this.partner.t_key
		}
	}

});

import { render, staticRenderFns } from "./MapWrapperPlaceholder.vue?vue&type=template&id=5c1e0e66"
import script from "./MapWrapperPlaceholder.vue?vue&type=script&lang=ts"
export * from "./MapWrapperPlaceholder.vue?vue&type=script&lang=ts"
import style0 from "./MapWrapperPlaceholder.vue?vue&type=style&index=0&id=5c1e0e66&prod&scope=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapHeader: require('/app/components/map/MapHeader.vue').default,MapTruckCardNew: require('/app/components/map/MapTruckCardNew.vue').default,MapChooseSizeNew: require('/app/components/map/MapChooseSizeNew.vue').default,MapTruckCarouselNew: require('/app/components/map/MapTruckCarouselNew.vue').default})

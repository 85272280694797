
import Vue from 'vue';
import type { PropType } from 'vue';
import { routesNames } from '@/router';
import CART from '@/store/modules/CartModule';
import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';
import MapTruckCard from '@/components/map/MapTruckCard.vue';
import Booking from '~/types/booking/booking';
import { Truck } from '@/types/truck';
import { ITruckClickPayLoad } from '~/plugins/tracking';

export default Vue.extend({
  name: 'MapTruckCarousel',

  components: { MapTruckCard },

  props: {
    truckList: {
      type: Array as PropType<Truck[]>,
      required: true,
    },
    onLandingPage: Boolean,
  },

  data() {
    return {
      carousel_idx: 0,
      loader: false,
    };
  },

  computed: {
    CART: () => CART,
    TRUCKS: () => TRUCKS,
    UTILS: () => UTILS,

    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },

    truckOrderedByAvailability(): Truck[] {
      return [...this.truckList].sort((a: Truck, b: Truck) => b.order - a.order);
    },

    truckCounter(): string {
      return this.truckList.length > 1 ? `${this.carousel_idx + 1}/${this.truckList.length}` : '';
    },
  },

  mounted() {
    // Run the truck tracking here for the first one in the list
    this.triggerTruckClickEvent(this.truckOrderedByAvailability[0]);
  },

  watch: {
    truckList() {
      this.carousel_idx = 0; // Reset idx when user clicks outside of the dialog to close it
    },
  },

  methods: {
    async toUpsells(truck: string): Promise<void> {
      const booking = new Booking(this.CART.booking);
      booking.truck = truck;
      await this.CART.STORE_BOOKING_DATA(booking);
      this.$router.push({ name: routesNames.upsells_1 });
    },

	triggerTruckClickEvent(truck: Partial<Truck>): void {
		if (!truck || !truck.id || this.onLandingPage) {
			return;
		}
		const [page, page_num] = this.truckCounter ? this.truckCounter.split('/') : [1, 1];
		const payload = {
			truck_id: truck.id,
			site_id: truck.position?.site_id,
			lat: truck.position?.lat,
			lng: truck.position?.lng,
			page: +page,
			num_pages: +page_num,
		} as ITruckClickPayLoad;

		setTimeout(() => {
			const slots_status = this.TRUCKS.current_week_slots_status[truck.id!];
			if (slots_status) {
			payload.blocked_slots = slots_status.blocked_slots;
			payload.available_slots = slots_status.available_slots;
			this.$tracking.truck_click(payload);
			}
		}, 2000);
	},

    previous(): void {
      this.carousel_idx = this.carousel_idx - 1 < 0 ? this.truckList.length - 1 : this.carousel_idx - 1;
      this.$nextTick(() => {
        this.triggerTruckClickEvent(this.truckOrderedByAvailability[this.carousel_idx]);
      });
    },

    next(): void {
      this.carousel_idx = this.carousel_idx + 1 === this.truckList.length ? 0 : this.carousel_idx + 1;
      this.$nextTick(() => {
        this.triggerTruckClickEvent(this.truckOrderedByAvailability[this.carousel_idx]);
      });
    },
  },
});


import Vue from 'vue';
import SectionHeading from '@/components/general/utils/SectionHeading.vue';

export default Vue.extend({
	name: 'Summary123',
	components: {
		SectionHeading
	}
});

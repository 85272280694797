
import Vue from 'vue';

import UTILS from '@/store/modules/UtilityModule';
import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import globalAws from '@/mixins/globalAws';

type PartnerEntry = {
	bu: string | null;
	name: string;
	href: string;
	src: string;
	alt: string;
	width: number;
};

export default Vue.extend({

	name: 'Partners',

	components: { SectionHeading },

	data() {
		return {
			width_default: 128 as number,
			width_a1: 58 as number,
			awsPath: globalAws,
		};
	},

	computed: {
		UTILS: () => UTILS,

		domain(): string {
			return this.$getDomain();
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},
		partners(): PartnerEntry[] {
			const partners = [
				{
					bu: null,
					name: 'Invers',
					href: 'https://invers.com/de/',
					src: '/img/logo_invers.png',
					alt: 'Invers partner logo',
					width: this.width_default
				},
				{
					bu: null,
					name: 'A1',
					href: 'https://www.a1.net/',
					src: '/img/logo_a1.png',
					alt: 'A1 partner logo',
					width: 58
				},
				{
					bu: 'at',
					name: 'Bellaflora',
					href: 'https://www.bellaflora.at/',
					src: '/img/logo_bellaflora.png',
					alt: 'Bellaflora partner logo',
					width: this.width_default
				},
				{
					bu: 'at',
					name: 'WEBA',
					href: 'https://www.sos-weba.at/',
					src: '/img/logo_weba.png',
					alt: 'WEBA partner logo',
					width: this.width_default
				},
				{
					bu: 'de',
					name: 'Onsite',
					href: 'https://123-transporter.de/',
					src: '/img/logo_onsite.png',
					alt: 'Onsite partner logo',
					width: this.isMobile ? 200 : 240
				},
				{
					bu: 'de',
					name: 'AE Transporte',
					href: 'https://123-transporter.de/',
					src: '/img/logo_ae_transporte.png',
					alt: 'AE Transporte partner logo',
					width: this.isMobile ? 180 : 200
				},
				{
					bu: 'de',
					name: 'Tandel Transporter',
					href: 'https://123-transporter.de/',
					src: '/img/logo_tandel_transporter.png',
					alt: 'Tandel Transporter partner logo',
					width: this.isMobile ? 140 : 200
				},
				{
					bu: 'de',
					name: 'Wis Mobil',
					href: 'https://123-transporter.de/',
					src: '/img/logo_wis_mobil.png',
					alt: 'Wis Mobil partner logo',
					width: this.isMobile ? 140 : 200
				}
			] as PartnerEntry[];

			return partners.filter((partner: PartnerEntry) => {
				return partner.bu === null || partner.bu === this.$getDomain();
			});
		}
	}

});

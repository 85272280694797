
import Vue from 'vue';

import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import PricingCard from '@/components/home/PricingCard.vue';

export default Vue.extend({
	name: 'Pricing',

	components: { SectionHeading, PricingCard }
});

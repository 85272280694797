
import Vue from 'vue';
import type { PropType } from 'vue';
import dayjs from 'dayjs';
import { IDateTimeSearch } from '@/types/misc_data';
import currentDomain from '@/mixins/currentDomain';

export default Vue.extend({
	name: 'DateTimePicker',

	mixins: [currentDomain],

	props: {
		min: {
			type: String,
			default: () => dayjs().format() // now
		},
		max: {
			type: String,
			default: undefined
		},
		parentDates: {
			type: Object as PropType<IDateTimeSearch>,
			default: undefined
		},
		dateFromExtraZone: {
			type: String,
			default: ''
		},
		disableRange: Boolean,
		onlyStartTime: Boolean,
		onlyEndTime: Boolean
	},

	data() {
		return {
			dayjs: dayjs,
			picker_date: null as string | null,
			picker_range: null as string[] | null,

			start_time: null as string | null,
			end_time: null as string | null,

			time_slots_start: ['00:00', '06:00', '12:00', '18:00'],
			time_slots_end: ['05:59', '11:59', '17:59', '23:59'],
		};
	},

	computed: {
		dateRange(): string[] | null {
			// Sorted date range in case user chooses start date after end date
			if (this.picker_range && this.picker_range.length > 1) {
				return this.picker_range.slice().sort((a, b) => {
					return a > b ? 1 : a < b ? -1 : 0;
				});
			}
			return this.picker_range;
		},

		localeToBCP47(): string {
			return this.$i18n.locale.split('-')[0];
		},

		sameDay(): boolean {
			if (!this.dateRange) {
				return false;
			}
			return this.dateRange.every((slot) => slot === (this.dateRange as string[])[0]);
		},

		showTimeSlots(): boolean {
			return this.disableRange ? this.picker_date !== null : this.picker_range !== null;
		},

		minTimeProcessed(): string {
			if (this.disableRange && this.onlyEndTime) {
				// add 6 hours here since it's using the booking end time for min
				return dayjs(this.min).add(6, 'hours').format()
			}
			return this.min
		},

		disableNext(): boolean {
			if (this.disableRange) {
				return this.onlyStartTime ? !this.picker_date || !this.start_time : !this.picker_date || !this.end_time;
			}
			return !this.picker_range || !this.start_time || !this.end_time;
		},

		startTimeDisabledList(): string[] | null {
			if ((this.disableRange && !this.picker_date) || (!this.disableRange && !this.dateRange)) {
				return null;
			}
			const black_list = [];
			// use min which is either a booking date_time or now
			const min_time = dayjs(this.minTimeProcessed).format('HH:mm');

			const date = this.dateRange ? this.dateRange[0] : this.picker_date;

			const slots = this.onlyEndTime ? this.time_slots_end : this.time_slots_start;

			// Check if start day chosen is min
			if (date === dayjs(this.min).format('YYYY-MM-DD')) {
				for (const t in slots) {
					if (slots[t] < min_time) {
						black_list.push(slots[t]);
					}
				}
				// Remove the last slot when using default min (now) because we can still choose current slot
				// $options.propsData contains props that WERE passed and not the default ones
				if (this.$options.propsData && !Object.keys(this.$options.propsData).includes('min')) {
					black_list.pop();
				}
			}
			// Check if there is a max and start date chosen is the same day
			if (this.max && date === dayjs(this.max).format('YYYY-MM-DD')) {
				// If disable range (upgrade) substract 6 hours so user can't choose the same time as booking start
				const max_time = this.disableRange ? dayjs(this.max).subtract(6, 'hours').format('HH:mm') : dayjs(this.max).format('HH:mm');
				for (const t in slots) {
					if (slots[t] > max_time) {
						black_list.push(slots[t]);
					}
				}
			}

			return black_list;
		},

		endTimeDisabledList(): string[] | null {
			const black_list = [];
			// Get the start time if there is one (Priority to start_time)
			// const start_time_picked = this.start_time ? this.start_time : this.startTimeMin[0]

			if (this.start_time && this.sameDay) {
				const start_index = this.time_slots_start.indexOf(this.start_time);
				// look through time_slots_end and push times for which i is less than start index
				for (const i in this.time_slots_end) {
					if ((i as any) < start_index) {
						black_list.push(this.time_slots_end[i]);
					}
				}
			}
			// if max is set and max date was picked, add the timeslots outside of range to the blacklist
			if (this.max) {
				const max_date_picked = this.onlyEndTime
					? this.picker_date && this.picker_date === dayjs(this.max).format('YYYY-MM-DD')
					: this.dateRange && this.dateRange.includes(dayjs(this.max).format('YYYY-MM-DD'));
				if (max_date_picked) {
					const max_time = dayjs(this.max).format('HH:mm');
					for (const t in this.time_slots_end) {
						if (this.time_slots_end[t] > max_time) {
							black_list.push(this.time_slots_end[t]);
						}
					}
				}
			}

			return black_list;
		},

		startDateTimeDisplay(): string | null {
			if (!this.dateRange || (this.disableRange && !this.picker_date)) {
				return null;
			}

			const date = this.disableRange ? this.picker_date : this.dateRange[0];

			if (!this.start_time) {
				return dayjs(date).format('dd, DD. MMMM');
			} else {
				return this.formatDateTime(`${date}T${this.start_time}`);
			}
		},

		endDateTimeDisplay(): string | null {
			if (!this.dateRange) {
				return null;
			}

			const end_date = this.dateRange.length > 1 ? this.dateRange[1] : this.dateRange[0];

			if (!this.end_time) {
				return dayjs(end_date).format('dd, DD. MMMM');
			} else {
				return this.formatDateTime(`${end_date}T${this.end_time}`);
			}
		}
	},

	watch: {
		startTimeDisabledList() {
			if (this.startTimeDisabledList && this.start_time) {
				for (const t in this.time_slots_start) {
					if (this.startTimeDisabledList.includes(this.time_slots_start[t]) && this.time_slots_start[t] === this.start_time) {
						this.start_time = this.time_slots_start[+t + 1];
					}
				}
			}
		},

		endTimeDisabledList() {
			// Update end_time if it was already set and now disabled
			if (this.endTimeDisabledList && this.end_time && this.endTimeDisabledList.includes(this.end_time)) {
				for (const t in this.time_slots_end) {
					if (!this.endTimeDisabledList.includes(this.time_slots_end[t])) {
						this.end_time = this.time_slots_end[t];
						return;
					}
				}
			}
		},

		parentDates() {
			if (!this.parentDates) {
				this.reset();
				return;
			}

			const dates = this.parentDates;

			if (this.disableRange) {
				if (this.onlyStartTime) {
					this.picker_date = dayjs(dates.start).format('YYYY-MM-DD');
					this.start_time = dayjs(dates.start).format('HH:mm');
				} else {
					this.picker_date = dayjs(dates.end).format('YYYY-MM-DD');
					this.end_time = dayjs(dates.end).format('HH:mm');
				}
			} else {
				this.picker_range = [dayjs(dates.start).format('YYYY-MM-DD'), dayjs(dates.end).format('YYYY-MM-DD')];
				this.start_time = dayjs(dates.start).format('HH:mm');
				this.end_time = dayjs(dates.end).format('HH:mm');
			}
		}
	},

	methods: {
		chooseDates() {
			if (this.disableRange && !this.disableNext) {
				this.$emit('picker-date', `${this.picker_date}T${this.onlyStartTime ? this.start_time : this.end_time}`);
				this.close();
				return;
			}

			if (this.disableNext || !this.dateRange) {
				return;
			}
			const same_day = this.dateRange && this.dateRange.length < 2;

			const start = `${this.dateRange[0]}T${this.start_time}`;
			const end = `${this.dateRange[same_day ? 0 : 1]}T${this.end_time}`;

			this.$emit('picker-payload', { start: start, end: end });
			this.close();
		},

		formatDateTime(date: string): string {
			if (this.isHuDomain) {
				return dayjs(date).format('dd, MMMM DD, HH:mm');
			}

			return dayjs(date).format('dd, DD. MMMM, HH:mm');
		},

		reset(): void {
			this.picker_range = null;
			this.picker_date = null;
			this.start_time = null;
			this.end_time = null;
		},

		close(): void {
			this.reset();
			this.$emit('close');
		}
	},
});

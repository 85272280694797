
import Vue from 'vue';
import dayjs from 'dayjs';

import UTILS from '@/store/modules/UtilityModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import { ApiCustomerReview } from '~/types/misc_data';
import { ErrorResponse } from '~/types/api_helper';

export default Vue.extend({
	name: 'CustomerReviews',

	data() {
		return {
			reviews: [] as ApiCustomerReview[],
			review_idx: 0,
			showMore: [] as boolean[],
			dayjs: dayjs,
			isExpanded: false,
		};
	},

	computed: {
		UTILS: () => UTILS,
		MISC_DATA: () => MISC_DATA,
		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		textSizeOnDevice(): number {
			return this.isMobile ? 100 : 160;
		}
	},

	methods: {
		toggleShowMore(idx: any) {
			this.isExpanded = true;
			this.$set(this.showMore, idx, !this.showMore[idx]);
		},
  	},

	watch: {
		review_idx: {
			handler (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.isExpanded = false;
					this.showMore = [];
				}
			},
		},
	},

	async created() {
		const response = await this.UTILS.GET_CUST_REVIEWS(this.$getDomain());
		if(!(response instanceof ErrorResponse)){
			this.reviews = response;
		}
	},

	mounted() {
    	this.showMore = this.reviews.map(() => false);
  	}
});


import Vue from 'vue';

import MISC_DATA from '@/store/modules/MiscDataModule';

import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import ProtectionPackageCard from '~/components/home/ProtectionPackageCard.vue';

export default Vue.extend({
	name: 'ProtectionPackages',

	components: { SectionHeading, ProtectionPackageCard },

	computed: {
		MISC_DATA: () => MISC_DATA,
		// show all packages from basedata except NONE
		available_packages(): any {
			return this.MISC_DATA.basedata.features.protection_packages.filter(
				(e: string) => e != 'NONE'
			);
		},
	},
});

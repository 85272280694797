
import Vue from 'vue';
import type { PropType } from 'vue';
import { routesNames } from '@/router';
import CART from '@/store/modules/CartModule';
import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';
import MapTruckCardNew from '@/components/map/MapTruckCardNew.vue';
import Booking from '~/types/booking/booking';
import { Truck, ITruckGroupBySize  } from '@/types/truck';
import { ITruckClickPayLoad } from '~/plugins/tracking';

export default Vue.extend({
  name: 'MapTruckCarouselNew',

  components: { MapTruckCardNew },

  props: {
    truckList: {
      type: Array as PropType<Truck[]>,
      required: true,
    },
	trucks: {
		type: Object as PropType<ITruckGroupBySize>,
		default: null
	},
	disableOverlay: {
		type: Boolean,
		default: false
	},
    onLandingPage: Boolean,
  },

  data() {
    return {
      carousel_idx: 0,
      loader: false,
	  trigerData: {
	  	triggerClick: 0,
		triggerId: '',
	  },
    };
  },

  computed: {
    CART: () => CART,
    TRUCKS: () => TRUCKS,
    UTILS: () => UTILS,

    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },

    truckOrderedByAvailability(): Truck[] {
      return [...this.truckList].sort((a: Truck, b: Truck) => b.order - a.order);
    },

    truckCounter(): string {
      return this.truckList.length > 1 ? `${this.carousel_idx + 1}/${this.truckList.length}` : '';
    },
  },

  mounted() {
    // Run the truck tracking here for the first one in the list
    this.trigerClickEvent();
  },

  watch: {
    truckList() {
      this.carousel_idx = 0; // Reset idx when user clicks outside of the dialog to close it
    },
  },

  methods: {
    async toUpsells(truck: string): Promise<void> {
      const booking = new Booking(this.CART.booking);
      booking.truck = truck;
      await this.CART.STORE_BOOKING_DATA(booking);
      this.$router.push({ name: routesNames.upsells_1 });
    },

	trigerClickEvent() {
		const currentTruck = this.truckOrderedByAvailability[this.carousel_idx];
		this.trigerData.triggerId = currentTruck.id;
		this.trigerData.triggerClick += 1;
	},

	trigerTruckClickEvent(val: any) {
		const currentTruck = this.truckOrderedByAvailability[this.carousel_idx];

		if (!currentTruck || !currentTruck.id || this.onLandingPage) {
			return;
		}

		const [page, page_num] = this.truckCounter ? this.truckCounter.split('/') : [1, 1];

		const payload = {
			truck_id: currentTruck.id,
			site_id: currentTruck.position?.site_id,
			lat: currentTruck.position?.lat,
			lng: currentTruck.position?.lng,
			page: +page,
			num_pages: +page_num,
			blocked_slots: val.blocked_slots,
			available_slots: val.available_slots,
		} as ITruckClickPayLoad;

		this.$tracking.truck_click(payload);
	},

    previous(): void {
      this.carousel_idx = this.carousel_idx - 1 < 0 ? this.truckList.length - 1 : this.carousel_idx - 1;
      this.$nextTick(() => {
        this.trigerClickEvent();
      });
    },

    next(): void {
      this.carousel_idx = this.carousel_idx + 1 === this.truckList.length ? 0 : this.carousel_idx + 1;
      this.$nextTick(() => {
        this.trigerClickEvent();
      });
    },

	updateTruckSize(val: string) {
		this.$emit('updateTruckSize', val);
	}
  },
});
